import { Form, type FormInstance, Select } from 'antd';
import { useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthStore from '../../store/auth';

const CountryCitySelector = ({
  form,
  country_id,
  city_id,
}: {
  form: FormInstance;
  country_id?: number;
  city_id?: number;
}): JSX.Element => {
  const { t } = useTranslation();

  const getCountries = useAuthStore(({ getAuthCountries }) => getAuthCountries);
  const countries = useAuthStore(({ authCountries }) => authCountries);
  const getCities = useAuthStore(({ getAuthCities }) => getAuthCities);
  const cities = useAuthStore(({ authCities }) => authCities);

  const [isChange, setIsChange] = useState(false);

  useLayoutEffect(() => {
    getCountries();
  }, []);

  useLayoutEffect(() => {
    if (country_id && !isChange) {
      form.setFieldValue('country_id', country_id);
      getCities(String(country_id));
    } else if (countries.length > 0 && isChange) {
      form.setFieldValue(
        'country_id',
        countries.sort((a, b) => a.name.localeCompare(b.name))[0]?.id,
      );
      getCities(String(countries.sort((a, b) => a.name.localeCompare(b.name))[0]?.id));
    }
  }, [countries]);

  useLayoutEffect(() => {
    if (city_id && !isChange) {
      form.setFieldValue('city_id', city_id);
    } else if (cities.length > 0 && isChange) {
      form.setFieldValue('city_id', cities.sort((a, b) => a.name.localeCompare(b.name))[0].id);
    }
  }, [cities]);

  const optionsCountries = useMemo(() => {
    if (countries.length <= 0) return [];
    return countries.map(({ name, id }) => {
      return {
        value: id,
        label: name,
      };
    });
  }, [countries]);

  const optionsCities = useMemo(() => {
    if (cities.length <= 0) return [];
    return cities.map(({ name, id }) => {
      return {
        value: id,
        label: name,
      };
    });
  }, [cities]);

  const handleChangeCountry = (value: string) => {
    setIsChange(true);
    form.setFieldValue('country_id', value);
    getCities(value);
  };

  return (
    <>
      <Form.Item
        name="country_id"
        label={t('inputLabels.country')}
        rules={[{ required: true, message: t('inputRulesMessages.country') }]}
      >
        <Select
          showSearch
          style={{ width: '100%' }}
          onChange={handleChangeCountry}
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={optionsCountries}
        />
      </Form.Item>

      <Form.Item
        name="city_id"
        label={t('inputLabels.city')}
        rules={[{ required: true, message: t('inputRulesMessages.city') }]}
      >
        <Select
          showSearch
          style={{ width: '100%' }}
          disabled={!form.getFieldValue('country_id')}
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={optionsCities}
        />
      </Form.Item>
    </>
  );
};
export default CountryCitySelector;
