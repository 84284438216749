import { Button, type FormProps, Typography, Form, Input, Modal, Select } from 'antd';

import useAuthStore from '../../../store/auth';
import openNotification from '../../../libs/tooltip';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { useState } from 'react';
import CountryCitySelector from '../../CountryCitySelector';
import {
  type AuthCity,
  type AuthCountry,
  type Geolocation,
} from '../../../interfaces/requestAuthTypes';

const { Title } = Typography;

export default function ChangeLocation({
  country,
  city,
}: {
  country: AuthCountry;
  city: AuthCity;
}): JSX.Element {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const changeGeolocation = useAuthStore(({ changeGeolocation }) => changeGeolocation);
  const getUserInfo = useAuthStore(state => state.getUser);

  const success = () => {
    Modal.success({
      content: t('settings.location.success'),

      onOk() {
        getUserInfo();
        // window.location.reload();
      },
    });
  };

  const fail = () => {
    openNotification(t('settings.location.fail'));
  };

  const onFinish: FormProps<Geolocation>['onFinish'] = async values => {
    const data = {
      country_id: values.country_id,
      city_id: values.city_id,
    };
    const response = await changeGeolocation(data);
    if (typeof response === 'undefined') {
      fail();
    } else {
      success();
    }
  };

  return (
    <div className={styles.wrapper}>
      <Title level={4}>{t('settings.location.title')}</Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
      >
        <CountryCitySelector
          form={form}
          country_id={country?.id}
          city_id={city?.id}
        />

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
          >
            {t('settings.location.change')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
