import {
  Alert,
  Button,
  Card,
  Descriptions,
  Divider,
  Empty,
  Table,
  type TableColumnsType,
  Typography,
  Image,
} from 'antd';
import { useLayoutEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import useAuthStore from '../../store/auth';
import history from '../../libs/history';
import styles from './styles.module.scss';
import useSettingsStore from '../../store/settings';
import ChangePassword from '../../components/Pages/ChangePassword';
import ChangeLocation from '../../components/Pages/ChangeLocation';
import type SettingsOrder from '../../interfaces/SettingsOrder';

const { Title, Text } = Typography;

export default function AccauntManagement(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userInfo = useAuthStore(({ user }) => user);
  const kycVerification = useAuthStore(({ profile }) => profile.kyc_verified_at?.length > 0);
  const profileInfo = useAuthStore(({ profile }) => profile);
  const userLogout = useAuthStore(({ logout }) => logout);
  const getYouBoughtItems = useSettingsStore(({ getYouBought }) => getYouBought);
  const getYouSoldItems = useSettingsStore(({ getYouSold }) => getYouSold);
  const youBoughtItems = useSettingsStore(({ youBought }) => youBought);
  const youSoldItems = useSettingsStore(({ youSold }) => youSold);
  const storeLoading = useSettingsStore(({ loadinng }) => loadinng);

  const itemsDescription = useMemo(() => {
    if (kycVerification) {
      return [
        {
          key: 1,
          label: t('settings.mainInfo.firstName'),
          children: profileInfo.first_name ?? '\u2013',
        },
        {
          key: 2,
          label: t('settings.mainInfo.lastName'),
          children: profileInfo.last_name ?? '\u2013',
        },
        {
          key: 3,
          label: t('settings.mainInfo.phone'),
          children: userInfo.phone ?? '\u2013',
        },
        {
          key: 4,
          label: t('settings.mainInfo.birthday'),
          children: profileInfo.birth_date ?? '\u2013',
        },
      ];
    }
    return [
      {
        key: 1,
        label: t('settings.mainInfo.phone'),
        children: userInfo.phone ?? '\u2013',
      },
    ];
  }, [kycVerification]);

  useLayoutEffect(() => {
    getYouBoughtItems();
    getYouSoldItems();
  }, []);

  const columns: TableColumnsType<SettingsOrder> = [
    {
      title: '#',
      dataIndex: 'currency_code',
      key: 'currency_code',
      render: (_, { currency_code: currencyCode, currency_type: currencyType }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Image
            src={
              currencyType === 'crypto'
                ? 'http://cryptologos.cc/logos/bitcoin-btc-logo.svg'
                : 'https://cryptologos.cc/logos/usd-coin-usdc-logo.svg'
            }
            alt={'payment method logo'}
            preview={false}
            width={16}
          />
          <p style={{ marginLeft: 8 }}>{currencyCode}</p>
        </div>
      ),
    },
    {
      title: '#',
      dataIndex: 'currency_amount',
      key: 'currency_amount',
    },
  ];

  return (
    <div className={styles.container}>
      <Title level={3}>{userInfo.name}</Title>

      {!kycVerification && (
        <div className={styles.verification}>
          <Alert
            message={t('settings.kycVerify.alert')}
            type="error"
            showIcon
          />
          <Button
            type="primary"
            className={styles.btn}
            onClick={() => {
              history.push('/kyc');
            }}
          >
            {t('settings.kycVerify.btnKycVerification')}
          </Button>
          <Text
            type="secondary"
            className={styles.description}
          >
            {t('settings.kycVerify.helper')}
          </Text>
        </div>
      )}
      <Title level={4}>{t('settings.mainInfo.title')}</Title>
      <Descriptions
        column={2}
        layout="vertical"
        colon={false}
        items={itemsDescription}
        className={styles.descriptions}
      />
      <Divider />
      <ChangeLocation
        country={profileInfo.country}
        city={profileInfo.city}
      />
      <Divider />
      <Title level={4}>{t('settings.statistic.title')}</Title>
      <div className={styles.statistic}>
        <Card>
          <Title level={5}>{t('settings.statistic.sold')}</Title>
          {Object.keys(youSoldItems).length > 0 || storeLoading.sold ? (
            <Table
              columns={columns}
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              dataSource={Object.keys(youSoldItems).map((index: any) => youSoldItems[index])}
              showHeader={false}
              pagination={false}
              loading={storeLoading.sold}
              rowKey={record => record.currency_code}
            />
          ) : (
            <Empty className={styles.empty} />
          )}
          {Object.keys(youSoldItems).length > 6 && (
            <Button
              type="link"
              onClick={() => {
                navigate('/settings/statistic-list', { state: { type: 'sold' } });
              }}
            >
              {t('settings.statistic.btnMore')}
            </Button>
          )}
        </Card>
        <Card>
          <Title level={5}>{t('settings.statistic.bought')}</Title>
          {Object.keys(youBoughtItems).length > 0 || storeLoading.bought ? (
            <Table
              columns={columns}
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              dataSource={Object.keys(youBoughtItems).map((index: any) => youBoughtItems[index])}
              showHeader={false}
              pagination={false}
              loading={storeLoading.bought}
              rowKey={record => record.currency_code}
            />
          ) : (
            <Empty className={styles.empty} />
          )}
          {Object.keys(youBoughtItems).length > 6 && (
            <Button
              type="link"
              onClick={() => {
                navigate('/settings/statistic-list', { state: { type: 'bought' } });
              }}
            >
              {t('settings.statistic.btnMore')}
            </Button>
          )}
        </Card>
      </div>
      <Divider />
      <ChangePassword />
      <Divider />
      <Title level={4}>{t('settings.actions.title')}</Title>
      <Button
        onClick={async () => {
          await userLogout();
        }}
      >
        {t('logout')}
      </Button>
    </div>
  );
}
