import { type Axios, type AxiosPromise } from 'axios';

class AuthEndpoint {
  axios: Axios;
  constructor(axios: Axios) {
    this.axios = axios;
  }

  authorization: (data: object) => AxiosPromise = async data =>
    await this.axios.post('/auth/login', data);

  logout: () => AxiosPromise = async () => await this.axios.post('/auth/logout');

  registration: (data: object) => AxiosPromise = async data =>
    await this.axios.post('/auth/register', data);

  phoneVerify: (data: object) => AxiosPromise = async data =>
    await this.axios.post('/auth/verify-phone', data);

  resendVerificationCode: (data: object) => AxiosPromise = async data =>
    await this.axios.post('/auth/resend-verification-code', data);

  resetPasswordPhoneVerify: (data: object) => AxiosPromise = async data =>
    await this.axios.post('/auth/reset-password-phone', data);

  resetPassword: (data: object) => AxiosPromise = async data =>
    await this.axios.post('/auth/reset-password', data);

  changePassword: (data: object) => AxiosPromise = async data =>
    await this.axios.post('/auth/me/reset-password', data);

  refreshToken: () => AxiosPromise = async () => await this.axios.post('/auth/refresh');

  userInfo: () => AxiosPromise = async () => await this.axios.get('/auth/me');

  getCountry: () => AxiosPromise = async () => await this.axios.get('/countries');

  getCity: (country: string) => AxiosPromise = async country =>
    await this.axios.get(`/cities/${country}`);

  changeGeolocation: (data: object) => AxiosPromise = async data => await this.axios.post('/auth/geolocation', data);

  setLocale: (data: object) => AxiosPromise = async data => await this.axios.post('/locale', data);
}

export default AuthEndpoint;
